


















































import { Vue, Component, Prop } from "vue-property-decorator";
import { ShippingFeeInfoProperties } from './ShippingFeeInfoView.vue';
import { ShippingFeePricingDialogProperties } from './ShippingFeePricingDialogView.vue';
import CommonUtil from '@/utilities/CommonUtil';

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
            recordCounts: [10, 20, 30, 40, 50],
        },
        filter: {

        }
    })
})
export default class ShippingFeePricingView extends Vue {
    @Prop() private properties: ShippingFeeInfoProperties;
    @Prop() private shippingFeePricingDialog: ShippingFeePricingDialogProperties;

    public get shippingFee() {
        return this.properties.shippingFee;
    }

    public get headers() {
        return [
            {
                text: this.$t("text.payment-amount"),
                value: "paymentAmount",
                width: "20%"
            },
            {
                text: this.$t("text.weight-g"),
                value: "weight",
                width: "20%"
            },
            {
                text: this.$t("text.description"),
                value: "description"
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                align: "center",
                fixed: true,
                width: "120px",
                sortable: false,
            },
        ];
    }

    public get footerProps() {
        return {
            "items-per-page-options": this.$data.table.recordCounts,
        };
    }

    public create() {
        this.shippingFeePricingDialog.isNew = true;
        this.shippingFeePricingDialog.pricing = { 
            minimumPaymentAmount: 0,
            maximumPaymentAmount: 0,
            minimumWeight: 0,
            maximumWeight: 0,
            weightStep: 0,
            weightPrice: 0
        };
        this.shippingFeePricingDialog.visible = true;
    }

    public modify(item: any) {
        this.shippingFeePricingDialog.isNew = false;
        this.shippingFeePricingDialog.pricing = CommonUtil.clone(item);
        this.shippingFeePricingDialog.visible = true;
    }

    public remove(item: any) {
        const pricings: any[] = this.properties.shippingFee.pricings;
        pricings.splice(pricings.indexOf(item), 1);
    }
}
